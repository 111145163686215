import { defineStore } from 'pinia'

// 定义用户信息接口
interface UserInfo {
  id: number
  username: string
  nickname: string
  email: string
  userPic: string
  createTime: string
  updateTime: string
}

export const useUserStore = defineStore('user', {
  state: () => ({
    token: '',
    userInfo: {
      id: 0,
      username: '',
      nickname: '',
      email: '',
      userPic: '',  // 用于存储头像URL
      createTime: '',
      updateTime: ''
    } as UserInfo
  }),

  actions: {
    setToken(token: string) {
      this.token = token
      localStorage.setItem('token', token)
      const expiresAt = new Date().getTime() + 12 * 60 * 60 * 1000
      localStorage.setItem('tokenExpiresAt', expiresAt.toString())
    },

    getToken() {
      const token = localStorage.getItem('token')
      const expiresAt = localStorage.getItem('tokenExpiresAt')
      
      if (!token || !expiresAt) {
        return null
      }

      if (new Date().getTime() > parseInt(expiresAt)) {
        this.clearUser()
        return null
      }

      return token
    },

    setUserInfo(userInfo: UserInfo) {
      this.userInfo = {
        id: userInfo.id,
        username: userInfo.username,
        nickname: userInfo.nickname,
        email: userInfo.email,
        userPic: userInfo.userPic,
        createTime: userInfo.createTime,
        updateTime: userInfo.updateTime
      }
    },

    clearUser() {
      this.token = ''
      this.userInfo = {
        id: 0,
        username: '',
        nickname: '',
        email: '',
        userPic: '',
        createTime: '',
        updateTime: ''
      }
      localStorage.removeItem('token')
      localStorage.removeItem('tokenExpiresAt')
    },

    isLoggedIn() {
      return !!this.getToken()
    }
  }
})