import axios from 'axios'
import { useUserStore } from '../stores/user'

// 根据环境设置 baseURL
const baseURL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8088' 
  : 'https://api.pyzjxpro.cn'

// 重试配置
const RETRY_COUNT = 5  // 最大重试次数
const BASE_DELAY = 1000  // 基础延迟时间（毫秒）
const MAX_DELAY = 30000  // 最大延迟时间（毫秒）
const TIMEOUT = 15000  // 基础超时时间

// 创建 axios 实例
const request = axios.create({
  baseURL,
  timeout: TIMEOUT,
  withCredentials: true
})

// 计算指数退避延迟时间
const getExponentialDelay = (retryCount: number): number => {
  // 基础延迟 * (2 ^ 重试次数) + 随机抖动
  const exponentialDelay = BASE_DELAY * Math.pow(2, retryCount) + Math.random() * 1000
  // 确保不超过最大延迟时间
  return Math.min(exponentialDelay, MAX_DELAY)
}

// 添加重试机制
const retryRequest = async (error: any) => {
  const config = error.config
  config.retryCount = config.retryCount || 0

  if (config.retryCount >= RETRY_COUNT) {
    return Promise.reject(error)
  }
  
  config.retryCount++
  
  // 使用指数退避计算延迟时间
  const delay = getExponentialDelay(config.retryCount)

  config.timeout = TIMEOUT * Math.pow(2, config.retryCount)
  
  // 创建延迟 Promise
  await new Promise(resolve => setTimeout(resolve, delay))
  
  console.log(`Retrying request (${config.retryCount}/${RETRY_COUNT}) after ${delay}ms: ${config.url}`)
  return request(config)
}

// 请求拦截器
request.interceptors.request.use(
  config => {
    if (config.url && !config.url.startsWith('/')) {
      config.url = '/' + config.url
    }
    
    if (process.env.NODE_ENV === 'development' && config.url?.startsWith('/api')) {
      config.url = config.url.replace('/api', '')
    }
    
    config.headers = config.headers || {}
    
    const userStore = useUserStore()
    const token = userStore.getToken()
    
    if (token) {
      config.headers['Authorization'] = token
    }
    
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const { config, response, code } = error

    // 如果是 401 错误，直接退出登录
    if (response && response.status === 401) {
      const userStore = useUserStore()
      userStore.clearUser()
      window.location.href = '/login'
      return Promise.reject(error)
    }

    // 如果请求已经重试过或不需要重试，直接返回错误
    if (!config || config.retryCount >= RETRY_COUNT) {
      return Promise.reject(error)
    }

    // 处理超时错误
    if (code === 'ECONNABORTED' && error.message.includes('timeout')) {
      console.log('Request timeout, applying exponential backoff...')
      return retryRequest(error)
    }

    // 特定错误状态码或网络错误才重试
    const shouldRetry = [408, 500, 502, 503, 504].includes(response?.status) || !response
    
    if (shouldRetry) {
      return retryRequest(error)
    }

    console.error('Request Error:', error)
    return Promise.reject(error)
  }
)

export default request 