import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
// 导入 naive-ui
import naive from 'naive-ui'
import './utils/axios'
import { gsap } from 'gsap'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'

// 注册 GSAP 插件
gsap.registerPlugin(MotionPathPlugin)

const app = createApp(App)

app.use(createPinia())
app.use(router)
// 使用 naive-ui
app.use(naive)

// 将 gsap 添加到全局属性中
app.config.globalProperties.$gsap = gsap

window.addEventListener('error', (e) => {
  if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
    e.stopPropagation()
    e.preventDefault()
  }
})

app.mount('#app')