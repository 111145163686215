<template>
  <div class="footer">
    <div class="beian-info">
      <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener">
        蜀ICP备2024105868号-1
      </a>
      <span class="divider">|</span>
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=36010602000346" 
         target="_blank" 
         rel="noopener"
         class="police-beian">
        <img src="https://spring-1330641240.cos.ap-guangzhou.myqcloud.com/%E5%A4%87%E6%A1%88%E5%9B%BE%E6%A0%87-1.png" 
             alt="备案图标" 
             class="beian-icon" />
        赣公网安备36010602000346
      </a>
    </div>
  </div>
</template>

<style scoped>
.footer {
  padding: 16px 0;
  text-align: center;
  background-color: transparent;
  margin-top: auto;
  width: 100%;
}

.beian-info {
  font-size: 13px;
  color: #999;
  line-height: 1.5;
  opacity: 0.8;
}

.beian-info a {
  color: #999;
  text-decoration: none;
  transition: all 0.3s ease;
}

.beian-info a:hover {
  color: #666;
  opacity: 1;
}

.divider {
  margin: 0 10px;
  color: #ccc;
}

.police-beian {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.beian-icon {
  height: 14px;
  width: auto;
  vertical-align: middle;
  opacity: 0.8;
}

.police-beian:hover .beian-icon {
  opacity: 1;
}
</style> 