import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

import MainLayout from './layout/MainLayout.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_n_notification_provider = _resolveComponent("n-notification-provider")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!
  const _component_n_dialog_provider = _resolveComponent("n-dialog-provider")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, null, {
    default: _withCtx(() => [
      _createVNode(_component_n_dialog_provider, null, {
        default: _withCtx(() => [
          _createVNode(_component_n_message_provider, null, {
            default: _withCtx(() => [
              _createVNode(_component_n_notification_provider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_router_view, null, {
                    default: _withCtx(({ Component, route }) => [
                      (route.meta?.layout === 'blank')
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
                        : (_openBlock(), _createBlock(MainLayout, { key: 1 }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                            ]),
                            _: 2
                          }, 1024))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})