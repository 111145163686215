import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import MainLayout from '../layout/MainLayout.vue'
import EmptyLayout from '../layout/EmptyLayout.vue'
import { useUserStore } from '../stores/user'
import ZjxView from '../views/ZjxView.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'articles',
        name: 'articles',
        component: () => import('../views/ArticlesView.vue')
      },
      {
        path: 'article/:id',
        name: 'article-detail',
        component: () => import('../views/ArticleDetailView.vue')
      },
      {
        path: 'categories',
        name: 'categories',
        component: () => import('../views/CategoriesView.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('../views/ProfileView.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('../views/SettingsView.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'article/edit/:id',
        name: 'article-edit',
        component: () => import('../views/EditArticleView.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'article/write',
        name: 'write-article',
        component: () => import('../views/EditArticleView.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'home/article/:id',
        name: 'home-article-detail',
        component: () => import('../views/HomeArticleDetailView.vue'),
        meta: { keepAlive: false }
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('../views/SearchResultView.vue')
      },
      {
        path: 'comments',
        name: 'comments',
        component: () => import('../views/CommentsView.vue')
      },
      {
        path: 'comment/:id',
        name: 'comment-detail',
        component: () => import('../views/CommentDetailView.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'messages',
        name: 'messages',
        component: () => import('../views/MessagesView.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'messages/:id',
        name: 'message-detail',
        component: () => import('../views/MessageDetailView.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/',
    component: EmptyLayout,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/LoginView.vue'),
        meta: { 
          requiresAuth: false,
          layout: 'blank'
        }
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('../views/RegisterView.vue'),
        meta: { 
          requiresAuth: false,
          layout: 'blank'
        }
      },
      {
        path: 'forget-password',
        name: 'forget-password',
        component: () => import('../views/ForgetPasswordView.vue'),
        meta: { 
          requiresAuth: false,
          layout: 'blank'
        }
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('../views/ResetPasswordView.vue'),
        meta: { 
          requiresAuth: false,
          layout: 'blank'
        }
      }
    ]
  },
  {
    path: '/zjx',
    component: () => import('../views/ZjxView.vue'),
    meta: {
      layout: 'blank',
      title: '欢迎页面',
      requiresAuth: false
    }
  },
  {
    path: '/zjx/surprise',
    name: 'surprise',
    component: () => import('../views/SurpriseView.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/NotFoundView.vue')
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === 'home-article-detail') {
      return { top: 0 }
    }
    
    if (to.name === 'home' && savedPosition) {
      return savedPosition
    }
    
    return { top: 0 }
  }
})

// 路由守卫
router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  const isLoggedIn = userStore.isLoggedIn()

  if (to.meta.requiresAuth && !isLoggedIn) {
    next('/login')
  } else if (to.path === '/login' && isLoggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router