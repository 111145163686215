<template>
  <n-layout position="absolute">
    <!-- 顶部导航栏 -->
    <n-layout-header 
      bordered 
      class="fixed-header"
      :class="{ 'header-hidden': isHeaderHidden }"
    >
      <n-space justify="space-between" align="center" style="height: 100%">
        <!-- Logo 和菜单组合在一起 -->
        <n-space align="center">
          <!-- Logo 部分 -->
          <n-space align="center" style="min-width: 200px; cursor: pointer" @click="handleLogoClick">
            <n-icon size="32" color="#18a058">
              <leaf />
            </n-icon>
            <n-h2 style="margin: 0; color: #18a058">lifestream</n-h2>
          </n-space>
          
          <!-- 导航菜单 -->
          <n-menu
            mode="horizontal"
            :options="menuOptions"
            :value="activeKey"
            @update:value="handleMenuUpdate"
          />
        </n-space>

        <!-- 右侧工具栏 -->
        <n-space align="center" style="min-width: 500px; justify-content: flex-end">
          <!-- 搜索框 -->
          <n-input-group>
            <n-input
              v-model:value="searchText"
              placeholder="搜索文章..."
              @keyup.enter="handleSearch"
              round
              style="width: 200px"
            >
              <template #prefix>
                <n-icon :component="SearchOutline" />
              </template>
            </n-input>
            <n-button type="primary" ghost round @click="handleSearch">
              搜索
            </n-button>
          </n-input-group>

          <!-- 时间显示 -->
          <n-space align="center" class="datetime-wrapper">
            <n-icon size="20" color="#18a058">
              <time-outline />
            </n-icon>
            <n-text class="datetime-text">{{ currentDateTime }}</n-text>
          </n-space>
          
          <!-- 用户头像和下拉菜单 -->
          <n-dropdown 
            trigger="click"
            :options="userMenuOptions" 
            @select="handleUserMenuSelect"
          >
            <n-avatar
              round
              :size="40"
              :src="userAvatar"
              :fallback="defaultAvatar"
            />
          </n-dropdown>
        </n-space>
      </n-space>
    </n-layout-header>

    <!-- 主要内容区域 -->
    <n-layout position="absolute" class="main-content">
      <!-- 内容区域 -->
      <n-layout-content 
        content-style="padding: 24px;" 
        class="layout-content"
        style="display: flex; flex-direction: column;"
      >
        <div style="flex: 1;">
          <router-view v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
        </div>
        <Footer v-if="showFooter" />
      </n-layout-content>
    </n-layout>
  </n-layout>
</template>
<script setup lang="ts">
import { h, ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useMessage, useDialog, NIcon } from 'naive-ui'
import type { MenuOption } from 'naive-ui'
import { RouterLink } from 'vue-router'
import { CreateOutline } from '@vicons/ionicons5'
import {
  BookOutline,
  HomeOutline,
  SearchOutline,
  LogOutOutline,
  SettingsOutline,
  PersonCircleOutline,
  LogInOutline,
  ListOutline,
  FolderOpenOutline,
  Leaf,
  TimeOutline,
  ChatboxOutline
} from '@vicons/ionicons5'
import { useUserStore } from '../stores/user'
import request from '../utils/axios'
import Footer from '@/components/Footer.vue'

const router = useRouter()
const route = useRoute()
const message = useMessage()
const dialog = useDialog()
const userStore = useUserStore()

// 搜索文本
const searchText = ref('')

// 当前激活的菜单项
const activeKey = ref(getDefaultActiveKey())

// 根当前路由获取默认激活的菜单项
function getDefaultActiveKey() {
  const path = route.path
  if (path === '/') return ''  // 首页时不选中任何菜单项
  if (path === '/articles') return 'articles'
  if (path === '/article/write') return 'article/write'
  if (path === '/categories') return 'categories'
  if (path.startsWith('/article/')) return 'articles'
  return path.slice(1)
}

// 监听路由变化
watch(
  () => route.path,
  () => {
    activeKey.value = getDefaultActiveKey()
  }
)

// 定义用户信息接口
interface UserInfo {
  id: number
  username: string
  nickname: string
  email: string
  userPic: string
  createTime: string
  updateTime: string
}

// 定义API响应接口
interface ApiResponse<T> {
  code: number
  message: string
  data: T
}

// 获取用户信息
const fetchUserInfo = async () => {
  try {
    const response = await request.get<ApiResponse<UserInfo>>('/user/userinfo')
    if (response.data.code === 0) {
      userStore.setUserInfo(response.data.data)
    } else {
      message.error(response.data.message || '获取用户信息失败')
    }
  } catch (error) {
    console.error('获取用户信息失败:', error)
    message.error('获取用户信息失败')
  }
}

// 在组件挂载时获取用户息
onMounted(() => {
  if (userStore.isLoggedIn()) {
    fetchUserInfo()
  }
})

// 用户头像
const userAvatar = computed(() => {
  return userStore.userInfo.userPic || defaultAvatar
})

// 默认头像
const defaultAvatar = 'path/to/default/avatar.png'

// 渲染图标
const renderIcon = (icon: any) => {
  return () => h(NIcon, null, { default: () => h(icon) })
}

// 菜单选项
const menuOptions: MenuOption[] = [
  {
    label: '内容管理',
    key: 'content',
    icon: renderIcon(SettingsOutline),
    children: [
      {
        label: '文章分类',
        key: 'categories',
        icon: renderIcon(FolderOpenOutline),
        path: '/categories'
      },
      {
        label: '我的评论',
        key: 'comments',
        icon: renderIcon(ChatboxOutline),
        path: '/comments'
      }
    ]
  },
  {
    label: '文章管理',
    key: 'article-manage',
    icon: renderIcon(BookOutline),
    children: [
      {
        label: '我的文章',
        key: 'articles',
        icon: renderIcon(ListOutline),
        path: '/articles'
      },
      {
        label: '写文章',
        key: 'article/write',
        icon: renderIcon(CreateOutline),
        path: '/article/write'
      }
    ]
  }
]

// 添加消息接口定义
interface Message {
  id: number
  conversationId: number
  senderId: number
  receiverId: number
  content: string
  createdAt: string
  isRead: string
}

interface GroupedMessages {
  [conversationId: number]: Message[]
}

// 添加消息状态
const groupedMessages = ref<GroupedMessages>({})
const hasUnreadMessages = ref(false)

// 获取消息列表并按conversationId归类
const fetchMessages = async () => {
  try {
    const response = await request.get('/conversation/myMessage')
    if (response.data.code === 0) {
      const messages: Message[] = response.data.data
      
      // 按conversationId归类消息
      const grouped: GroupedMessages = {}
      messages.forEach((msg: Message) => {
        if (!grouped[msg.conversationId]) {
          grouped[msg.conversationId] = []
        }
        grouped[msg.conversationId].push(msg)
      })
      
      // 检查是否有未读消息
      hasUnreadMessages.value = messages.some(msg => msg.isRead === 'no')
      
      // 更新状态
      groupedMessages.value = grouped
    }
  } catch (error) {
    console.error('获取消息失败:', error)
  }
}

// 在组件挂载时获取消息
onMounted(() => {
  if (userStore.isLoggedIn()) {
    fetchMessages()
    // 每分钟刷新一次消息
    messageTimer = setInterval(fetchMessages, 60000)
  }
})

onUnmounted(() => {
  if (messageTimer) {
    clearInterval(messageTimer)
  }
})

// 修改用户菜单选项
const userMenuOptions = computed(() => {
  if (!userStore.isLoggedIn()) {
    return [{
      label: '登录',
      key: 'login',
      icon: renderIcon(LogInOutline)
    }]
  }
  
  return [
    {
      label: userStore.userInfo.username || '用户',
      key: 'header',
      disabled: true
    },
    {
      type: 'divider',
      key: 'd1'
    },
    {
      label: () => h('div', { style: 'display: flex; align-items: center; gap: 8px;' }, [
        '私信消息',
        hasUnreadMessages.value 
          ? h('sup', { 
              style: 'background-color: #f5222d; color: white; padding: 2px 6px; border-radius: 10px; font-size: 12px;'
            }, '新') 
          : null
      ]),
      key: 'messages',
      icon: renderIcon(ChatboxOutline)
    },
    {
      label: '个人空间',
      key: 'profile',
      icon: renderIcon(PersonCircleOutline)
    },
    {
      label: '设置',
      key: 'settings',
      icon: renderIcon(SettingsOutline)
    },
    {
      type: 'divider',
      key: 'd2'
    },
    {
      label: '退出登录',
      key: 'logout',
      icon: renderIcon(LogOutOutline)
    }
  ]
})

// 修改用户菜单选择处理函数
const handleUserMenuSelect = (key: string) => {
  switch (key) {
    case 'login':
      router.push('/login')
      break
    case 'messages':
      router.push('/messages')
      break
    case 'profile':
      router.push('/profile')
      break
    case 'settings':
      router.push('/settings')
      break
    case 'logout':
      showLogoutConfirm()
      break
  }
}

// 添加定时刷新消息的功能
let messageTimer: ReturnType<typeof setInterval> | null = null

onMounted(() => {
  if (userStore.isLoggedIn()) {
    fetchMessages()
    // 每分钟刷新一次消息
    messageTimer = setInterval(fetchMessages, 60000)
  }
})

onUnmounted(() => {
  if (messageTimer) {
    clearInterval(messageTimer)
  }
})

// 处理菜单选择
const handleMenuUpdate = (key: string, item: any) => {
  activeKey.value = key
  
  // 添加日志以便调试
  console.log('Menu key:', key)
  console.log('Menu item:', item)
  
  // 在 handleMenuUpdate 函数开始处添加
  console.log('Menu update triggered:', {
    key,
    itemPath: item.path,
    hasChildren: !!item.children,
    fullItem: item
  })
  
  // 如果是父菜单项，不进行路由跳转
  if (item.children) {
    return
  }
  
  // 如果菜单项有 path 属性，直接使用
  if (item.path) {
    router.push(item.path)
    return
  }

  // 根据 key 进由转
  switch (key) {
    case 'home':
      router.push('/')
      break
    case 'articles':
      router.push('/articles')
      break
    case 'categories':
      router.push('/categories')
      break
    default:
      console.warn('Unhandled menu key:', key)
  }
}

// 显示退出确认对话框
const showLogoutConfirm = () => {
  dialog.warning({
    title: '确认退出',
    content: '确定要退出登录吗？',
    positiveText: '确定',
    negativeText: '取消',
    onPositiveClick: () => {
      handleLogoutConfirmed()
    }
  })
}

// 处理确认退出
const handleLogoutConfirmed = async () => {
  try {
    await request.get('/user')
    
    userStore.clearUser()
    delete request.defaults.headers.common['Authorization']
    
    message.success('已退出登录')
    router.push('/login')
  } catch (error) {
    console.error('退出登录错误:', error)
    message.error('退出登录失败，请试')
  }
}

// 处理搜索
const handleSearch = () => {
  if (!searchText.value.trim()) {
    message.warning('请输入搜索内容')
    return
  }
  
  // 添加特殊关键词判断
  if (searchText.value.trim() === '欢迎zjx') {
    router.push('/zjx')
    searchText.value = '' // 清空搜索框
    return
  }
  
  // 原有的搜索逻辑
  router.push({
    path: '/search',
    query: {
      keyword: searchText.value.trim()
    }
  })
}

// 添加时间相关的代码
const currentDateTime = ref('')

const updateDateTime = () => {
  const now = new Date()
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }
  currentDateTime.value = now.toLocaleString('zh-CN', options)
}

let timer: ReturnType<typeof setInterval> | null = null

onMounted(() => {
  updateDateTime()
  timer = setInterval(updateDateTime, 1000)
})

onUnmounted(() => {
  if (timer) {
    clearInterval(timer)
    timer = null
  }
})

// 控制页脚显示
const showFooter = computed(() => {
  // 在登录和注册页面不显示页脚
  return !['login', 'register'].includes(route.name as string)
})

// 添加 Logo 点击处理函数
const handleLogoClick = () => {
  router.push('/')
  activeKey.value = ''  // 清除菜单选中状态
}

// 添加导航栏显示控制逻辑
const isHeaderHidden = ref(false)
let lastScrollTop = 0
let scrollTimer: ReturnType<typeof setTimeout> | null = null

const handleScroll = () => {
  if (scrollTimer) {
    clearTimeout(scrollTimer)
  }

  scrollTimer = setTimeout(() => {
    const currentScrollTop = window.scrollY
    
    // 只有当滚动距离大于导航栏高度时才触发隐藏
    if (currentScrollTop > 64) {
      // 向下滚动
      if (currentScrollTop > lastScrollTop) {
        isHeaderHidden.value = true
      } 
      // 向上滚动
      else if (currentScrollTop < lastScrollTop) {
        isHeaderHidden.value = false
      }
    } else {
      // 滚动距离小于导航栏高度时始终显示
      isHeaderHidden.value = false
    }
    
    lastScrollTop = currentScrollTop
  }, 50) // 添加50ms的防抖
}

// 添加滚动事件监听
onMounted(() => {
  window.addEventListener('scroll', handleScroll, { passive: true })
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  if (scrollTimer) {
    clearTimeout(scrollTimer)
  }
})
</script>
<style scoped>
.n-layout {
  height: 100vh;
}

.n-layout-header {
  padding: 0 24px !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.n-input {
  width: 300px;
}

.n-input :deep(.n-input__border),
.n-input :deep(.n-input__state-border) {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
}

.n-button {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}

.n-avatar {
  cursor: pointer;
}

.n-avatar:hover {
  opacity: 0.9;
}

/* 图标样式 */
:deep(.n-menu-item-content__icon) {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

:deep(.n-icon) {
  width: 18px;
  height: 18px;
}

/* 菜单样式 */
:deep(.n-menu-item) {
  &:hover {
    color: #18a058 !important;
  }
}

:deep(.n-menu-item-content--selected) {
  color: #18a058 !important;
  background-color: #e8f5e9 !important;
}

:deep(.n-menu-item-content__icon) {
  color: inherit !important;
}

/* 修复响应式布局 */
.layout-sider {
  display: none;
}

.layout-content {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

/* 美化滚动条 */
.layout-content::-webkit-scrollbar {
  width: 6px;
}

.layout-content::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 3px;
}

.layout-content::-webkit-scrollbar-track {
  background: transparent;
}

/* 菜单展开/收起动画 */
:deep(.n-layout-sider-scroll-container) {
  transition: all 0.3s ease-in-out;
}

/* 子菜单样式 */
:deep(.n-submenu-children) {
  .n-menu-item-content {
    &:hover {
      color: #18a058 !important;
    }
  }
}

/* 选中状态的图标颜色 */
:deep(.n-menu-item-content--selected) .n-menu-item-content__icon {
  color: #18a058 !important;
}

/* 折叠时的图标样式 */
:deep(.n-menu--collapsed) {
  .n-menu-item-content__icon {
    margin-right: 0;
  }
}

/* 改进菜单交互样式 */
:deep(.n-menu-item-content) {
  transition: all 0.3s ease;
}

:deep(.n-menu-item-content:hover) {
  color: #18a058 !important;
  background-color: rgba(24, 160, 88, 0.1) !important;
}

:deep(.n-menu-item-content--selected) {
  color: #18a058 !important;
  background-color: rgba(24, 160, 88, 0.15) !important;
  font-weight: 500;
}

:deep(.n-submenu-children .n-menu-item-content--selected) {
  background-color: rgba(24, 160, 88, 0.15) !important;
}

/* 改进菜单展开/收起动画 */
:deep(.n-layout-sider-scroll-container) {
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

:deep(.n-menu--collapsed) {
  .n-menu-item-content__icon {
    margin: 0 auto;
  }
}

/* 改进子菜单样式 */
:deep(.n-submenu) {
  .n-submenu-children {
    .n-menu-item-content {
      padding-left: 32px !important;
    }
  }
}

/* 添加菜单项的过渡效果 */
:deep(.n-menu-item-content),
:deep(.n-submenu-children) {
  transition: all 0.3s ease-in-out !important;
}

/* 优化菜单滚动条 */
:deep(.n-layout-sider-scroll-container::-webkit-scrollbar) {
  width: 0;
  background: transparent;
}

:deep(.n-submenu .n-menu-item-content) {
  padding-left: 32px !important;
}

:deep(.n-submenu .n-menu-item-content__icon) {
  margin-right: 8px;
}

/* 添加 logo 相关样式 */
.n-icon {
  transition: transform 0.3s ease;
}

.n-icon:hover {
  transform: rotate(15deg);
}

/* 优化标题样式 */
.n-h2 {
  display: flex;
  align-items: center;
  font-weight: 600;
  transition: color 0.3s ease;
}

.n-space {
  transition: opacity 0.3s ease;
}

.n-space:hover {
  opacity: 0.9;
}

/* 添加时间显示相关样式 */
.datetime-wrapper {
  background-color: rgba(24, 160, 88, 0.1);
  padding: 6px 12px;
  border-radius: 16px;
  transition: all 0.3s ease;
}

.datetime-wrapper:hover {
  background-color: rgba(24, 160, 88, 0.2);
  transform: translateY(-1px);
}

.datetime-text {
  color: #18a058;
  font-weight: 500;
  font-family: "Courier New", Courier, monospace;
}

/* 时钟图标动画 */
.datetime-wrapper .n-icon {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* 添加水平菜单的样式 */
:deep(.n-menu.n-menu--horizontal) {
  border: none;
  height: 64px;
  padding: 0 24px;
}

:deep(.n-menu.n-menu--horizontal .n-menu-item) {
  height: 64px;
  line-height: 64px;
}

:deep(.n-menu.n-menu--horizontal .n-menu-item-content) {
  height: 64px;
  line-height: 64px;
  padding: 0 16px;
}

/* 调整搜索框样式 */
.n-input-group {
  width: auto;
}

/* 调整时间显示器样式 */
.datetime-wrapper {
  margin: 0 16px;
}

/* 添加固定导航栏样式 */
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 64px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* 添加隐藏导航栏的样式 */
.header-hidden {
  transform: translateY(-100%);
}

/* 调整主内容区域 */
.main-content {
  padding-top: 64px;
  min-height: 100vh;
}

/* 添加消息通知相关样式 */
:deep(.n-dropdown-option-body) {
  position: relative;
}

:deep(.n-dropdown-option--has-submenu) {
  padding-right: 36px;
}
</style>

