import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"flex":"1"} }

import { h, ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useMessage, useDialog, NIcon } from 'naive-ui'
import type { MenuOption } from 'naive-ui'
import { RouterLink } from 'vue-router'
import { CreateOutline } from '@vicons/ionicons5'
import {
  BookOutline,
  HomeOutline,
  SearchOutline,
  LogOutOutline,
  SettingsOutline,
  PersonCircleOutline,
  LogInOutline,
  ListOutline,
  FolderOpenOutline,
  Leaf,
  TimeOutline,
  ChatboxOutline
} from '@vicons/ionicons5'
import { useUserStore } from '../stores/user'
import request from '../utils/axios'
import Footer from '@/components/Footer.vue'

interface UserInfo {
  id: number
  username: string
  nickname: string
  email: string
  userPic: string
  createTime: string
  updateTime: string
}

// 定义API响应接口
interface ApiResponse<T> {
  code: number
  message: string
  data: T
}

// 获取用户信息
const defaultAvatar = 'path/to/default/avatar.png'

// 渲染图标
interface Message {
  id: number
  conversationId: number
  senderId: number
  receiverId: number
  content: string
  createdAt: string
  isRead: string
}

interface GroupedMessages {
  [conversationId: number]: Message[]
}

// 添加消息状态

export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  setup(__props) {

const router = useRouter()
const route = useRoute()
const message = useMessage()
const dialog = useDialog()
const userStore = useUserStore()

// 搜索文本
const searchText = ref('')

// 当前激活的菜单项
const activeKey = ref(getDefaultActiveKey())

// 根当前路由获取默认激活的菜单项
function getDefaultActiveKey() {
  const path = route.path
  if (path === '/') return ''  // 首页时不选中任何菜单项
  if (path === '/articles') return 'articles'
  if (path === '/article/write') return 'article/write'
  if (path === '/categories') return 'categories'
  if (path.startsWith('/article/')) return 'articles'
  return path.slice(1)
}

// 监听路由变化
watch(
  () => route.path,
  () => {
    activeKey.value = getDefaultActiveKey()
  }
)

// 定义用户信息接口
const fetchUserInfo = async () => {
  try {
    const response = await request.get<ApiResponse<UserInfo>>('/user/userinfo')
    if (response.data.code === 0) {
      userStore.setUserInfo(response.data.data)
    } else {
      message.error(response.data.message || '获取用户信息失败')
    }
  } catch (error) {
    console.error('获取用户信息失败:', error)
    message.error('获取用户信息失败')
  }
}

// 在组件挂载时获取用户息
onMounted(() => {
  if (userStore.isLoggedIn()) {
    fetchUserInfo()
  }
})

// 用户头像
const userAvatar = computed(() => {
  return userStore.userInfo.userPic || defaultAvatar
})

// 默认头像
const renderIcon = (icon: any) => {
  return () => h(NIcon, null, { default: () => h(icon) })
}

// 菜单选项
const menuOptions: MenuOption[] = [
  {
    label: '内容管理',
    key: 'content',
    icon: renderIcon(SettingsOutline),
    children: [
      {
        label: '文章分类',
        key: 'categories',
        icon: renderIcon(FolderOpenOutline),
        path: '/categories'
      },
      {
        label: '我的评论',
        key: 'comments',
        icon: renderIcon(ChatboxOutline),
        path: '/comments'
      }
    ]
  },
  {
    label: '文章管理',
    key: 'article-manage',
    icon: renderIcon(BookOutline),
    children: [
      {
        label: '我的文章',
        key: 'articles',
        icon: renderIcon(ListOutline),
        path: '/articles'
      },
      {
        label: '写文章',
        key: 'article/write',
        icon: renderIcon(CreateOutline),
        path: '/article/write'
      }
    ]
  }
]

// 添加消息接口定义
const groupedMessages = ref<GroupedMessages>({})
const hasUnreadMessages = ref(false)

// 获取消息列表并按conversationId归类
const fetchMessages = async () => {
  try {
    const response = await request.get('/conversation/myMessage')
    if (response.data.code === 0) {
      const messages: Message[] = response.data.data
      
      // 按conversationId归类消息
      const grouped: GroupedMessages = {}
      messages.forEach((msg: Message) => {
        if (!grouped[msg.conversationId]) {
          grouped[msg.conversationId] = []
        }
        grouped[msg.conversationId].push(msg)
      })
      
      // 检查是否有未读消息
      hasUnreadMessages.value = messages.some(msg => msg.isRead === 'no')
      
      // 更新状态
      groupedMessages.value = grouped
    }
  } catch (error) {
    console.error('获取消息失败:', error)
  }
}

// 在组件挂载时获取消息
onMounted(() => {
  if (userStore.isLoggedIn()) {
    fetchMessages()
    // 每分钟刷新一次消息
    messageTimer = setInterval(fetchMessages, 60000)
  }
})

onUnmounted(() => {
  if (messageTimer) {
    clearInterval(messageTimer)
  }
})

// 修改用户菜单选项
const userMenuOptions = computed(() => {
  if (!userStore.isLoggedIn()) {
    return [{
      label: '登录',
      key: 'login',
      icon: renderIcon(LogInOutline)
    }]
  }
  
  return [
    {
      label: userStore.userInfo.username || '用户',
      key: 'header',
      disabled: true
    },
    {
      type: 'divider',
      key: 'd1'
    },
    {
      label: () => h('div', { style: 'display: flex; align-items: center; gap: 8px;' }, [
        '私信消息',
        hasUnreadMessages.value 
          ? h('sup', { 
              style: 'background-color: #f5222d; color: white; padding: 2px 6px; border-radius: 10px; font-size: 12px;'
            }, '新') 
          : null
      ]),
      key: 'messages',
      icon: renderIcon(ChatboxOutline)
    },
    {
      label: '个人空间',
      key: 'profile',
      icon: renderIcon(PersonCircleOutline)
    },
    {
      label: '设置',
      key: 'settings',
      icon: renderIcon(SettingsOutline)
    },
    {
      type: 'divider',
      key: 'd2'
    },
    {
      label: '退出登录',
      key: 'logout',
      icon: renderIcon(LogOutOutline)
    }
  ]
})

// 修改用户菜单选择处理函数
const handleUserMenuSelect = (key: string) => {
  switch (key) {
    case 'login':
      router.push('/login')
      break
    case 'messages':
      router.push('/messages')
      break
    case 'profile':
      router.push('/profile')
      break
    case 'settings':
      router.push('/settings')
      break
    case 'logout':
      showLogoutConfirm()
      break
  }
}

// 添加定时刷新消息的功能
let messageTimer: ReturnType<typeof setInterval> | null = null

onMounted(() => {
  if (userStore.isLoggedIn()) {
    fetchMessages()
    // 每分钟刷新一次消息
    messageTimer = setInterval(fetchMessages, 60000)
  }
})

onUnmounted(() => {
  if (messageTimer) {
    clearInterval(messageTimer)
  }
})

// 处理菜单选择
const handleMenuUpdate = (key: string, item: any) => {
  activeKey.value = key
  
  // 添加日志以便调试
  console.log('Menu key:', key)
  console.log('Menu item:', item)
  
  // 在 handleMenuUpdate 函数开始处添加
  console.log('Menu update triggered:', {
    key,
    itemPath: item.path,
    hasChildren: !!item.children,
    fullItem: item
  })
  
  // 如果是父菜单项，不进行路由跳转
  if (item.children) {
    return
  }
  
  // 如果菜单项有 path 属性，直接使用
  if (item.path) {
    router.push(item.path)
    return
  }

  // 根据 key 进由转
  switch (key) {
    case 'home':
      router.push('/')
      break
    case 'articles':
      router.push('/articles')
      break
    case 'categories':
      router.push('/categories')
      break
    default:
      console.warn('Unhandled menu key:', key)
  }
}

// 显示退出确认对话框
const showLogoutConfirm = () => {
  dialog.warning({
    title: '确认退出',
    content: '确定要退出登录吗？',
    positiveText: '确定',
    negativeText: '取消',
    onPositiveClick: () => {
      handleLogoutConfirmed()
    }
  })
}

// 处理确认退出
const handleLogoutConfirmed = async () => {
  try {
    await request.get('/user')
    
    userStore.clearUser()
    delete request.defaults.headers.common['Authorization']
    
    message.success('已退出登录')
    router.push('/login')
  } catch (error) {
    console.error('退出登录错误:', error)
    message.error('退出登录失败，请试')
  }
}

// 处理搜索
const handleSearch = () => {
  if (!searchText.value.trim()) {
    message.warning('请输入搜索内容')
    return
  }
  
  // 添加特殊关键词判断
  if (searchText.value.trim() === '欢迎zjx') {
    router.push('/zjx')
    searchText.value = '' // 清空搜索框
    return
  }
  
  // 原有的搜索逻辑
  router.push({
    path: '/search',
    query: {
      keyword: searchText.value.trim()
    }
  })
}

// 添加时间相关的代码
const currentDateTime = ref('')

const updateDateTime = () => {
  const now = new Date()
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }
  currentDateTime.value = now.toLocaleString('zh-CN', options)
}

let timer: ReturnType<typeof setInterval> | null = null

onMounted(() => {
  updateDateTime()
  timer = setInterval(updateDateTime, 1000)
})

onUnmounted(() => {
  if (timer) {
    clearInterval(timer)
    timer = null
  }
})

// 控制页脚显示
const showFooter = computed(() => {
  // 在登录和注册页面不显示页脚
  return !['login', 'register'].includes(route.name as string)
})

// 添加 Logo 点击处理函数
const handleLogoClick = () => {
  router.push('/')
  activeKey.value = ''  // 清除菜单选中状态
}

// 添加导航栏显示控制逻辑
const isHeaderHidden = ref(false)
let lastScrollTop = 0
let scrollTimer: ReturnType<typeof setTimeout> | null = null

const handleScroll = () => {
  if (scrollTimer) {
    clearTimeout(scrollTimer)
  }

  scrollTimer = setTimeout(() => {
    const currentScrollTop = window.scrollY
    
    // 只有当滚动距离大于导航栏高度时才触发隐藏
    if (currentScrollTop > 64) {
      // 向下滚动
      if (currentScrollTop > lastScrollTop) {
        isHeaderHidden.value = true
      } 
      // 向上滚动
      else if (currentScrollTop < lastScrollTop) {
        isHeaderHidden.value = false
      }
    } else {
      // 滚动距离小于导航栏高度时始终显示
      isHeaderHidden.value = false
    }
    
    lastScrollTop = currentScrollTop
  }, 50) // 添加50ms的防抖
}

// 添加滚动事件监听
onMounted(() => {
  window.addEventListener('scroll', handleScroll, { passive: true })
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  if (scrollTimer) {
    clearTimeout(scrollTimer)
  }
})

return (_ctx: any,_cache: any) => {
  const _component_n_h2 = _resolveComponent("n-h2")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_menu = _resolveComponent("n-menu")!
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_input_group = _resolveComponent("n-input-group")!
  const _component_n_text = _resolveComponent("n-text")!
  const _component_n_avatar = _resolveComponent("n-avatar")!
  const _component_n_dropdown = _resolveComponent("n-dropdown")!
  const _component_n_layout_header = _resolveComponent("n-layout-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_n_layout_content = _resolveComponent("n-layout-content")!
  const _component_n_layout = _resolveComponent("n-layout")!

  return (_openBlock(), _createBlock(_component_n_layout, { position: "absolute" }, {
    default: _withCtx(() => [
      _createVNode(_component_n_layout_header, {
        bordered: "",
        class: _normalizeClass(["fixed-header", { 'header-hidden': isHeaderHidden.value }])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_space, {
            justify: "space-between",
            align: "center",
            style: {"height":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_space, { align: "center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_n_space, {
                    align: "center",
                    style: {"min-width":"200px","cursor":"pointer"},
                    onClick: handleLogoClick
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(NIcon), {
                        size: "32",
                        color: "#18a058"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Leaf))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_n_h2, { style: {"margin":"0","color":"#18a058"} }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("lifestream")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_n_menu, {
                    mode: "horizontal",
                    options: menuOptions,
                    value: activeKey.value,
                    "onUpdate:value": handleMenuUpdate
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_n_space, {
                align: "center",
                style: {"min-width":"500px","justify-content":"flex-end"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_n_input_group, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_n_input, {
                        value: searchText.value,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
                        placeholder: "搜索文章...",
                        onKeyup: _withKeys(handleSearch, ["enter"]),
                        round: "",
                        style: {"width":"200px"}
                      }, {
                        prefix: _withCtx(() => [
                          _createVNode(_unref(NIcon), { component: _unref(SearchOutline) }, null, 8, ["component"])
                        ]),
                        _: 1
                      }, 8, ["value"]),
                      _createVNode(_component_n_button, {
                        type: "primary",
                        ghost: "",
                        round: "",
                        onClick: handleSearch
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" 搜索 ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_n_space, {
                    align: "center",
                    class: "datetime-wrapper"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(NIcon), {
                        size: "20",
                        color: "#18a058"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(TimeOutline))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_n_text, { class: "datetime-text" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(currentDateTime.value), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_n_dropdown, {
                    trigger: "click",
                    options: userMenuOptions.value,
                    onSelect: handleUserMenuSelect
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_n_avatar, {
                        round: "",
                        size: 40,
                        src: userAvatar.value,
                        fallback: defaultAvatar
                      }, null, 8, ["src"])
                    ]),
                    _: 1
                  }, 8, ["options"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_n_layout, {
        position: "absolute",
        class: "main-content"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_layout_content, {
            "content-style": "padding: 24px;",
            class: "layout-content",
            style: {"display":"flex","flex-direction":"column"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_router_view, null, {
                  default: _withCtx(({ Component }) => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ]),
                  _: 1
                })
              ]),
              (showFooter.value)
                ? (_openBlock(), _createBlock(Footer, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})